


















































































































































































































































































































::v-deep .main-content {
  margin: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  & > .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .back-btn-content {
    margin: 40px 40px 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .content {
    flex: 1;
    height: 1%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    .upload-flex{
      display: flex;
.img-uploader {
      width: 218px;
      height: 258px;
      background: #f6f7f7;
      border: 1px dashed #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 10px;
      .img-box {
        display: block;
        max-width: 100%;
        max-height: 218px;
      }
      .add-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .add-icon {
          color: #7d7d7d;
          font-size: 24px;
        }
        .add-text {
          color: #7d7d7d;
          font-size: 12px;
          margin-top: 12px;
        }
      }
    }
    }
    
  }
}

  ::v-deep .text-price{
    &.el-input-number .el-input__inner {
      text-align: left;
  }
}
